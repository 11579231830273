<template>
  <div>
    <div class="serviceHeader">
      <div class="font-size-36 font-weight-bold text-dark mb-4">Reviews</div>
    </div>
    <a-table
      :loading="loadingGet"
      :columns="columns"
      :data-source="reviews"
      :customRow="customRow"
      :pagination="false"
    >
      <span slot="reviewer" slot-scope="data">
        {{ getValueFromSource(data, "user.first_name") }}
        {{ getValueFromSource(data, "user.last_name") }}
      </span>
      <span slot="comment" slot-scope="data">
        {{ getValueFromSource(data, "comment", "N/A") }}
      </span>
      <span slot="rate" slot-scope="data">
        <a-rate
          class="text-gold-colored"
          :value="getValueFromSource(data, 'rate', 0)"
          :disabled="true"
        />
      </span>
    </a-table>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { GET_ENTITY } from "@/store/actions";
import { get } from "lodash";

import ProfileMixin from "@/mixins/Profile";

export default {
  mixins: [ProfileMixin],
  components: {},
  props: {
    entity_type: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      reviews: [],
      columns: [
        {
          title: "Reviewer",
          key: "reviewer",
          rowKey: "_id",
          scopedSlots: { customRender: "reviewer" },
        },
        {
          title: "Comment",
          key: "comment",
          rowKey: "_id",
          scopedSlots: { customRender: "comment" },
        },
        {
          title: "Rate",
          key: "rate",
          scopedSlots: { customRender: "rate" },
        },
      ],
      customRow: (record) => {
        return {
          on: {
            click: (event) => {},
          },
        };
      },
    };
  },
  computed: {
    ...mapState("tourist", ["loadingGet"]),
  },
  created() {
    this.fetchEntity();
  },
  methods: {
    ...mapActions("tourist", {
      getEntity: GET_ENTITY,
    }),
    async fetchEntity() {
      try {
        this.loading = true;

        const response = await this.getEntity({
          entity_type: this.entity_type,
          entity_id: this.$route.params.id,
        });

        this.reviews = get(response, "entity.reviews", []);
      } catch (error) {
        this.$notification.error({ message: "Error fetching entity reviews" });
      } finally {
        this.loading = false;
      }
    },
    getValueFromSource(source, path, defaultValue = "") {
      return get(source, path, defaultValue);
    },
  },
};
</script>
<style scoped>
.ant-input.ant-input-disabled {
  background-color: white;
}
</style>
